import { trackingEvent } from '../../analytics';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';

export const sendConfirmationTrackingEvent = (email, status, errorType) => {
  const errorMessages = {
    token_invalid: 'Token is not valid or has expired',
    user_not_exist: 'User does not exist'
  };

  let errorSource = null;
  let eventResult = 'Successful';

  if (status !== 'OK') {
    errorSource = errorMessages[errorType] ?? 'Confirmation issues';
    eventResult = 'Failed';
  }

  trackingEvent(
    'confirmation_attempt',
    {
      user_email: email?.toLowerCase(),
      event_result: eventResult,
      error_source: errorSource
    },
    AMPLITUDE_SERVICE
  );
};

export const sendNetworkBlockedEvent = (email) => {
  const NETWORK_DISCONNECTION = 'Network disconnection';
  const NETWORK_BLOCKING = 'Network blocking confirmation service';
  const userOnLine = navigator.onLine;
  const errorSource = userOnLine ? NETWORK_BLOCKING : NETWORK_DISCONNECTION;

  trackingEvent(
    'confirmation_network_warning_alert',
    {
      email: email?.toLowerCase(),
      error_source: errorSource
    },
    AMPLITUDE_SERVICE
  );
};

export const handleUserResponse = (user) => {
  if (!user) return null;

  const cleanedUser = {
    ...user,
    email: user.email.replace(/\s+/g, '')
  };

  return cleanedUser;
};

export const prepareUserStateUpdate = (user, currentState) => {
  if (!user.position) {
    return {
      ...currentState,
      loadingForm: false,
      tokenValid: true,
      currentUser: user
    };
  }

  return {
    ...currentState,
    loadingForm: false,
    tokenValid: true,
    currentUser: user,
    position: {
      ...currentState.position,
      value: user.position
    }
  };
};
