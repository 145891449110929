import React, { Fragment } from 'react';
import { Avatar, Input, Row, Col, Button, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { base } from '../../../services/base';
import { headersSendForm } from '../../../utils';

export default function ValidView({
  state,
  setState,
  noMatch,
  onSubmit,
  onChangeUpload,
  uploadButton,
  renderFnameValidationError,
  renderLnameValidationError,
  renderPositionValidationError,
  t
}) {
  return (
    <Fragment>
      <form className="frmWide" onSubmit={onSubmit} noValidate>
        <Row>
          <Col span={24}>
            <ImgCrop rotate shape="round" modalWidth={850}>
              <Upload
                action={`${base.api}users/upload`}
                listType="picture-card"
                showUploadList={false}
                headers={headersSendForm}
                onChange={onChangeUpload}>
                {state.stateUpload.imageUrl ? (
                  <Avatar size={140} src={state.stateUpload.imageUrl} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
          </Col>
        </Row>

        <Row className="mt-20">
          <Col span={11}>
            <Input
              className="w100"
              type="text"
              name="fname"
              placeholder={t('confirmation.first_name')}
              autoComplete="off"
              defaultValue={
                state.currentUser.name
              } /** split until change model */
              onChange={(e) => {
                /** update state with value selected */
                setState({
                  ...state,
                  fname: {
                    ...state.fname,
                    value: e.target.value,
                    valid: true /** clear validation */
                  }
                });
              }}
              required
            />
            {renderFnameValidationError}
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Input
              className="w100"
              type="text"
              name="lname"
              placeholder={t('confirmation.last_name')}
              autoComplete="off"
              defaultValue={
                state.currentUser.lastname
              } /** split until change model */
              onChange={(e) => {
                /** update state with value selected */
                setState({
                  ...state,
                  lname: {
                    ...state.lname,
                    value: e.target.value,
                    valid: true /** clear validation */
                  }
                });
              }}
              required
            />
            {renderLnameValidationError}
          </Col>
        </Row>

        <Row className="mt-20">
          <Col span={11}>
            <Input
              className="w100"
              type="email"
              name="email"
              placeholder="Email"
              required
              value={state.currentUser.email}
              autoComplete="off"
            />
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Input
              className="w100"
              type="text"
              name="position"
              placeholder={t('confirmation.position')}
              autoComplete="off"
              defaultValue={state.currentUser.position}
              onChange={(e) => {
                /** update state with value selected */
                setState({
                  ...state,
                  position: {
                    ...state.position,
                    value: e.target.value,
                    valid: true
                  }
                });
              }}
              required
            />
            {renderPositionValidationError}
          </Col>
        </Row>

        <Row className="mt-20">
          <Col span={11}>
            <Input
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              className="w100"
              type="password"
              name="password"
              placeholder={t('confirmation.create')}
              onChange={(e) => {
                /** update state with value selected */
                setState({
                  ...state,
                  password: {
                    ...state.password,
                    value: e.target.value
                  },
                  repassword: {
                    ...state.password,
                    valid: true /** clear validation */
                  }
                });
              }}
              required
            />
            <label
              className={
                state.repassword.valid
                  ? 'lblInfoElement'
                  : ' lblInfoElement lblerrorfrm'
              }>
              {t('confirmation.pass_req')}
            </label>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Input
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              className="w100"
              type="password"
              name="repassword"
              placeholder={t('confirmation.retype')}
              onChange={(e) => {
                /** update state with value selected */
                setState({
                  ...state,
                  repassword: {
                    ...state.repassword,
                    value: e.target.value,
                    valid: true /** clear validation */
                  }
                });
              }}
              required
            />
          </Col>
          {noMatch && (
            <label className="lblInfoElement lblerrorfrm" style={{ left: 55 }}>
              {t('confirmation.no_match')}
            </label>
          )}
        </Row>
        <Button htmlType="submit" loading={state.isLoading}>
          {t('confirmation.continue')}
        </Button>
      </form>
    </Fragment>
  );
}
