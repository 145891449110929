/** React components  */
import React, { useState, useEffect } from 'react';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

/** Services */
import { userService, authService } from '../../services';

/**  import library for animations effects */
import { Animated } from 'react-animated-css';

/** import elements from library Antd */
import { Spin, Avatar } from 'antd';
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';

/** import hook for handle params */
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/** import components */
import ErrorValidationLabel from '../../components/Login/ValidationLabel';

/** import common functions from utils */
import {
  checkAllFieldsValid,
  reduceFormValues,
  txtFieldState,
  generateFormObjectSelect,
  getBase64
} from '../../utils';

/** import library for antd upload crop */
import { redirectSelectCompany } from '../login/login.helpers';

import InvalidView from './InvalidView';
import ValidView from './ValidView';
import { useConfirmation } from './hooks/useConfirmation';

const stateTemplate = {
  fname: {
    ...txtFieldState,
    fieldName: 'fname',
    required: true,
    requiredTxt: 'Name is required',
    formatErrorTxt: ''
  },
  lname: {
    ...txtFieldState,
    fieldName: 'lname',
    required: true,
    requiredTxt: 'Last name is required',
    formatErrorTxt: ''
  },
  password: {
    ...txtFieldState,
    fieldName: 'password',
    required: true,
    requiredTxt: 'Password is not valid',
    formatErrorTxt: "Passwords don't match"
  },
  email: {
    ...txtFieldState,
    fieldName: 'email',
    required: false,
    requiredTxt: '',
    formatErrorTxt: ''
  },
  position: {
    ...txtFieldState,
    fieldName: 'position',
    required: false,
    requiredTxt: '',
    formatErrorTxt: ''
  },
  repassword: {
    ...txtFieldState,
    fieldName: 'repassword',
    required: true,
    requiredTxt: 'Password is not valid',
    formatErrorTxt: "Passwords don't match"
  },
  allFieldsValid: false,
  isLoading: false,
  loadingForm: true,
  tokenValid: false,
  networkError: false,
  currentUser: {},
  stateUpload: { loading: false }
};

export default function ConfirmationView(props) {
  const { token } = useParams();
  const { state, setState, loadUser } = useConfirmation(token, stateTemplate);
  const [noMatch, setNoMatch] = useState(false);
  const { t } = useTranslation();
  const userLang = navigator.language || navigator.userLanguage;

  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };
    const msg =
      userLang.indexOf('es') !== -1
        ? 'Crea tu ' + '<span id="spanActive">' + 'Perfil' + '</span>'
        : 'Create your ' + '<span id="spanActive">' + 'Profile' + '</span>';
    document.getElementById('titleHeader').innerHTML = msg;
    EventEmitter.on('changeMainRoute', callback);
    loadUser();

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  // services
  async function updateUser(data) {
    await userService.update(data);
  }

  /** Logic component */
  const onSubmit = async (e) => {
    e.preventDefault();
    if (state.password.value !== state.repassword.value) {
      setNoMatch(true);
      return;
    }
    setNoMatch(false);

    const form = e.target;
    const formValues = reduceFormValues(form.elements, state);
    // add selects to validation
    formValues.position = generateFormObjectSelect(
      'position',
      'Position',
      state.position.value
    );
    const allFieldsValid = checkAllFieldsValid(formValues);
    setState({ ...state, ...formValues, allFieldsValid });

    if (allFieldsValid) {
      setState({
        ...state,
        isLoading: true
      });
      if (state.currentUser) {
        // TODO: firstName / lastName merge field? Role by default?
        const email = state.currentUser.email.toLowerCase().replace(/\s+/g, '');
        const data = {
          name: state.fname.value,
          lastname: state.lname.value,
          email: state.currentUser.email.replace(/\s+/g, ''),
          id: state.currentUser.id,
          is_active: true,
          role: email.includes('super@')
            ? 'superadmin'
            : state.currentUser.role || 'admin',
          password: state.password.value,
          position: state.position.value,
          companyId: state.currentUser.companyId || null
        };
        /** save image uploaded */
        data.image = state.stateUpload.name ? state.stateUpload.name : null;
        await updateUser(data);
        /** call auth */
        const dataAuth = {
          user: state.currentUser.email,
          password: state.password.value
        };
        const resp = await authService.index(dataAuth);

        return redirectSelectCompany(resp, props.history);
      }
    }
  };

  /** this function is triggered when upload image */
  const onChangeUpload = (info) => {
    if (info.file.status === 'uploading') {
      setState({
        ...state,
        stateUpload: {
          loading: true
        }
      });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response
      getBase64(info.file.originFileObj, (imageUrl) =>
        setState({
          ...state,
          stateUpload: {
            imageUrl,
            name: info.file.response.name,
            loading: false
          }
        })
      );
    }
  };

  /** component button */
  const uploadButton = (
    <div>
      {state.stateUpload.loading ? (
        <LoadingOutlined />
      ) : state.currentUser.image ? (
        <Avatar size={140} src={`${state.currentUser.image}`} />
      ) : (
        <Avatar className="FrmAvatar" size={140} icon={<UserOutlined />} />
      )}
    </div>
  );

  // validation
  const { fname, lname, position } = state;

  const renderFnameValidationError = fname.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={
        fname.typeMismatch
          ? fname.formatErrorTxt
          : t('confirmation.first_name_req')
      }
    />
  );

  const renderLnameValidationError = lname.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={
        lname.typeMismatch
          ? lname.formatErrorTxt
          : t('confirmation.last_name_req')
      }
    />
  );

  const renderPositionValidationError = position.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={
        position.typeMismatch
          ? position.formatErrorTxt
          : t('confirmation.position_req')
      }
    />
  );

  const renderContent = () => {
    if (state.loadingForm) {
      return <Spin spinning={true} />;
    }

    if (!state.tokenValid) {
      return (
        <InvalidView
          history={props.history}
          isNetworkError={state.networkError}
        />
      );
    }

    return (
      <ValidView
        state={state}
        setState={setState}
        noMatch={noMatch}
        onSubmit={onSubmit}
        onChangeUpload={onChangeUpload}
        uploadButton={uploadButton}
        renderFnameValidationError={renderFnameValidationError}
        renderLnameValidationError={renderLnameValidationError}
        renderPositionValidationError={renderPositionValidationError}
        t={t}
      />
    );
  };

  return (
    <Animated
      className="animatedWide"
      animationIn="fadeIn"
      animationInDuration={750}
      isVisible={true}>
      <div className="divWide">
        <div className="divSection">{renderContent()}</div>
      </div>
    </Animated>
  );
}
