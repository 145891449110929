import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import EventEmitter from 'react-native-eventemitter';
import { getSessionTokenData, getCurrentUser } from '../../utils/userUtils';
import InvalidLinkLayout from '../../layout/invalidLink';
import { NO_MODULE_ACCESS } from '../../constants/access';
import { base } from '../../services/base';
import './index.css';

export default function AnalyticsView(props) {
  const userSessionStorage = getSessionTokenData();
  const userLocalStorage = getCurrentUser();

  const sessionToken = JSON.parse(sessionStorage.getItem('sessionToken'));

  const { companyState, projectState } = useSelector((state) => state);

  const currentProject = projectState.allProjects.find(
    (project) => project.id === projectState.projectSelected
  );

  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  if (userSessionStorage?.role === 'client') {
    return (
      <div className="no-module-access">
        <InvalidLinkLayout type={NO_MODULE_ACCESS} />
      </div>
    );
  }

  return (
    <iframe
      allowFullScreen
      src={`${base.uiAnalytics}?id=${userSessionStorage?.id}&name=${userLocalStorage?.name}&lastname=${userLocalStorage?.lastname}&email=${userLocalStorage?.email}&companyId=${userSessionStorage?.companyId}&companyImage=${companyState?.currentCompany?.image || ''}&companyName=${encodeURIComponent(companyState?.currentCompany?.name)}&position=${encodeURIComponent(userSessionStorage?.position)}&role=${userSessionStorage?.role}&projectId=${currentProject?.id}&projectName=${encodeURIComponent(currentProject?.name)}&projectAddress=${currentProject?.address ? encodeURIComponent(currentProject?.address) : ''}&token=${sessionToken}`}
      className="iframe"
    />
  );
}
