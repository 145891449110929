/** React components  */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/** import elements from library Antd Framework */
import { Button, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

export default function InvalidView(props) {
  const { isNetworkError, history } = props;
  const { t } = useTranslation();
  const [message, setMessage] = useState('');

  useEffect(() => {
    const setMessageBasedOnNetwork = async () => {
      try {
        if (isNetworkError) {
          const networkMessage = navigator.onLine
            ? t('confirmation.network_blocking_alert')
            : t('confirmation.network_disconnection');
          setMessage(networkMessage);
          return;
        }
        setMessage(t('confirmation.invalid_token'));
      } catch (error) {
        Sentry.withScope((scope) => {
          scope.setContext('InvalidConfirmationView', {
            message: error.message
          });
          Sentry.captureException(error);
        });
        Sentry.captureException(error);
        setMessage(t('token.invalid'));
      }
    };

    setMessageBasedOnNetwork();
  }, [t, isNetworkError]);

  if (!message) return null;

  return (
    <div className="divErrorDivSection">
      <a href="/">
        <div
          className="imgDegrade"
          style={{ width: 120, height: 120, backgroundSize: 120 }}>
          &nbsp;
        </div>
      </a>
      <Alert
        message={message}
        type="error"
        style={{ width: '60%', margin: 'auto' }}
      />
      <Button onClick={() => history.push('/register')}>Register</Button>
    </div>
  );
}

InvalidView.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isNetworkError: PropTypes.bool
};
