import { useState } from 'react';
import { userService, authService } from '../../../services';
import { checkBackendAccess } from '../../../utils/checkBackendAccess';
import {
  sendConfirmationTrackingEvent,
  sendNetworkBlockedEvent,
  handleUserResponse,
  prepareUserStateUpdate
} from '../confirmation.helpers';
import * as Sentry from '@sentry/react';

export const useConfirmation = (token, initialState) => {
  const [state, setState] = useState(initialState);

  const loadUser = async () => {
    try {
      const statusServer = await checkBackendAccess(authService);
      if (!statusServer) {
        sendNetworkBlockedEvent(state.currentUser?.email);
        setState((prevState) => ({
          ...prevState,
          loadingForm: false,
          tokenValid: false,
          networkError: true
        }));
        return;
      }

      const userResponse = await userService.getbytoken(token);
      const processedUser = handleUserResponse(userResponse);

      if (!processedUser) {
        setState((prevState) => ({
          ...prevState,
          loadingForm: false,
          tokenValid: false,
          networkError: false
        }));
        sendConfirmationTrackingEvent(null, 'ERROR', 'token_invalid');
        return;
      }

      const newState = prepareUserStateUpdate(processedUser, state);
      setState(newState);
      sendConfirmationTrackingEvent(processedUser.email, 'OK', null);
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('confirmationView', {
          message: error.message
        });
        Sentry.captureException(error);
      });
      setState((prevState) => ({
        ...prevState,
        loadingForm: false,
        tokenValid: false,
        networkError: false
      }));
      sendConfirmationTrackingEvent(null, 'ERROR', 'unexpected_error');
    }
  };

  return {
    state,
    setState,
    loadUser
  };
};
